<template>
	<div v-if="bursary" class="md-layout" id="top">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard :bursary="bursary">
				<template slot="header">
					<h3 class="title">{{ bursary.title }}</h3>

					<div v-if="client" class="flex items-center justify-center">
						<h4 style="margin-right: 2rem">🏢 {{ client.companyName }}</h4>
						<h4>📅 {{ bursary.applicationDeadline }}</h4>
					</div>
					<md-button style="position: absolute; top: 3px; left: 10px; font-size: 30px !important" class="md-simple md-just-icon md-round modal-default-button">
						<md-icon>help</md-icon>
					</md-button>
				</template>

				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label">
						<div class="pc-view">Submit Required Documents</div>
						<div class="mobi-view"><i class="fas fa-location-arrow"></i></div>
					</template>
					<submit-documents ref="step1" @on-validated="onStepValidated" @test="addTest"></submit-documents>
				</wizard-tab>

				<wizard-tab v-if="showVideoSubmission" :before-change="() => validateStep('step2')">
					<template slot="label">
						<div class="pc-view">Video Submission</div>
						<div class="mobi-view"><i class="fas fa-location-arrow"></i></div>
					</template>
					<video-submission ref="step2" @on-validated="onStepValidated" @videoSubmission="addVideoSubmission"></video-submission>
				</wizard-tab>

				<wizard-tab v-if="showWrittenSubmission" :before-change="() => validateStep('step3')">
					<template slot="label">
						<div class="pc-view">Written Submission</div>
						<div class="mobi-view"><i class="fas fa-location-arrow"></i></div>
					</template>
					<written-submission ref="step3" @on-validated="onStepValidated" @writtenSubmission="addWrittenSubmission"></written-submission>
				</wizard-tab>
				<wizard-tab :before-change="() => validateStep('step4')">
					<template slot="label">
						<div class="pc-view">Review Application</div>
						<div class="mobi-view"><i class="fas fa-location-arrow"></i></div>
					</template>
					<review-application ref="step4" @on-validated="onStepValidated" v-bind:documents="documents"></review-application>
				</wizard-tab>
			</simple-wizard>
		</div>
	</div>
</template>

<script>
import SubmitDocuments from './wizard/formSteps/SubmitDocuments.vue';
import VideoSubmission from './wizard/formSteps/VideoSubmission.vue';
import WrittenSubmission from './wizard/formSteps/WrittenSubmission.vue';
import ReviewApplication from './wizard/formSteps/ReviewApplication.vue';
import { mapActions } from 'vuex';
import swal from 'sweetalert2';
import { WizardTab } from '@/components';
import SimpleWizard from './wizard/Wizard.vue';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '@/firebase/init';
import { store } from '../../../../../store/store';
export default {
	components: {
		SubmitDocuments,
		VideoSubmission,
		WrittenSubmission,
		ReviewApplication,
		SimpleWizard,
		WizardTab,
	},
	data() {
		return {
			wizardModel: {},
			bursaryId: this.$route.params.id,
			bursary: null,
			client: null,
			documents: null,
			videoSubmission: null,
			writtenSubmission: null,
		};
	},
	async created() {
		try {
			await store.dispatch('resetState');
			await this.fetchBursaryDocument();
			if (this.bursary && this.bursary.employerId) {
				await this.fetchClientDocument(this.bursary.employerId);
			}
		} catch (error) {
			return;
		}
	},
	computed: {
		showVideoSubmission() {
			return this.bursary && this.bursary.additionalSubmissions?.videoSubmission === 'Yes';
		},
		showWrittenSubmission() {
			return this.bursary && this.bursary.additionalSubmissions?.writtenSubmission === 'Yes';
		},
	},

	methods: {
		...mapActions(['saveSubmittedDocuments', 'saveVideoSubmissionLink', 'saveWrittenSubmissionLink']),
		addWrittenSubmission(value) {
			this.saveWrittenSubmissionLink(value);
		},
		addVideoSubmission(value) {
			this.saveVideoSubmissionLink(value);
		},
		addTest(value) {
			this.saveSubmittedDocuments(value);
		},
		async fetchBursaryDocument() {
			const bursaryRef = doc(db, 'bursaries', this.bursaryId);
			const bursarySnap = await getDoc(bursaryRef);
			if (bursarySnap.exists()) {
				this.bursary = bursarySnap.data();
			}
		},

		async fetchClientDocument(employerId) {
			try {
				const clientsCollection = collection(db, 'clients');
				const q = query(clientsCollection, where('userId', '==', employerId));
				const querySnapshot = await getDocs(q);

				if (!querySnapshot.empty) {
					querySnapshot.forEach(docSnap => {
						this.client = docSnap.data();
					});
				}
			} catch (error) {
				return;
			}
		},
		async validateStep(ref) {
			if (ref === 'step1') {
				const submissionValid = await this.$refs[ref].submitDocuments();
				if (!submissionValid) {
					return false;
				}
			}
			if (ref === 'step2') {
				const submissionValid = await this.$refs[ref].submitVideo();
				if (!submissionValid) {
					return false;
				}
			}
			if (ref === 'step3') {
				const submissionValid = await this.$refs[ref].submitWrittenSubmission();
				if (!submissionValid) {
					return false;
				}
			}
			return this.$refs[ref].validate();
		},
		onStepValidated(validated, model) {
			this.wizardModel = { ...this.wizardModel, ...model };
		},
	},
};
</script>
<style scoped>
.md-button .md-button-content i {
	font-size: 30px !important;
}
@media only screen and (max-width: 768px) {
	.pc-view {
		display: none;
	}
	.md-layout-item.md-xsmall-size-80 {
		min-width: 100%;
		overflow-x: hidden;
	}
	.md-card-wizard .nav-pills > li i {
		font-size: 0px;
	}
}

@media only screen and (min-width: 768px) {
	.mobi-view {
		display: none;
	}
}
</style>
