<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<h1 style="display: flex; align-items: center; justify-content: center">Bursaries</h1>

				<md-card-content>
					<md-table v-model="bursaryData">
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell md-label="Company Name" md-sort-by="companyName">{{ item.companyName }}</md-table-cell>
							<md-table-cell md-label="Bursary Name" md-sort-by="bursaryName">{{ item.title }}</md-table-cell>
							<md-table-cell md-label="Deadline" md-sort-by="deadline">{{ item.applicationDeadline }}</md-table-cell>
							<md-table-cell md-label="Action">
								<router-link :to="{ name: 'bursary-details', params: { id: item.bursaryId } }">
									<md-button class="jb-standard">View</md-button>
								</router-link>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		bursaryData: {},
	},
};
</script>

<style scoped>
.md-table + .md-table {
	margin-top: 16px;
}

.md-table-cell-container {
	display: flex;
	justify-content: flex-start;
}

.md-table-cell-container .md-button {
	width: auto !important;
}

.md-card [class*='md-card-header-'] {
	position: relative !important;
}
.md-button .md-button-content i {
	font-size: 30px !important;
}
</style>
